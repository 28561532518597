import React from 'react';
import './Home.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Container, Typography} from "@mui/material";

function Impressum() {
    return (
        <div className={"Centered"}>
            <div>
                <Typography variant={"h3"}>Offenlegung gemäß §25 MedienG</Typography>
                <Typography variant={"subtitle1"}>
               Amir Camdzic <br/>
                7221 Marz, Austria <br/>
                devkanza [at] gmail.com
            </Typography>
            </div>
        </div>
    );
}

export default Impressum;
