import React from 'react';
import './AboutMe.css';
import {Box, Button, Card, CardActions, CardContent, CardMedia, Container, Grid, Typography} from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import coding from './coding.jpg';
import gaming from './gaming.jpg';
import politics from './politics.jpg';
import trains from './trains.jpg';
import traveling from './traveling.jpg';
import Drawer from "./Drawer";

function Hobbies() {
    return (
        <div className={"About"}>
            <Typography variant={"h3"}>Hobbies</Typography>
            <Grid container spacing={2} className={"Grid"}>
                <Grid item>
                    <Card sx={{minWidth: 300}}>
                        <CardMedia
                            component="img"
                            height="140"
                            image={coding}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Coding
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                One of my hobbies for many years is coding, especially the development of Android applications
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                    <Card sx={{minWidth: 300}}>
                        <CardMedia
                            component="img"
                            height="140"
                            image={trains}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Trainspotting
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                               Since 2019 I have got a huge interest in public transportation, especially in trains, which I'm kind of obsessed with. I also do a lot of train travels abroad using Interrail.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" href={"https://train.kanza.dev"}>Railway Map</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item>
                    <Card sx={{minWidth: 300}}>
                        <CardMedia
                            component="img"
                            height="140"
                            image={traveling}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Traveling
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                As already mentioned is traveling also a part of my hobbies. One of my favorite travel destinations is Switzerland, which feature a lot of nature, beautiful cities, enormous mountains and good chocolate and fondue.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                    <Card sx={{minWidth: 300}}>
                        <CardMedia
                            component="img"
                            height="140"
                            image={gaming}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Gaming
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Since my childhood gaming has also been a substantial part of my hobbies. I developed a love for Nintendo games, that's the reason why I also have a Nintendo Switch. Otherwise I play a lot of PC games, especially Counter-Strike.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                <Card sx={{minWidth: 300}}>
                    <CardMedia
                        component="img"
                        height="140"
                        image={politics}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Politics
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Since 2015 politics had a huge impact on my youth and therefore I decided to be active at the Austrian Greens Youth and the Austrian Greens.
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            </Grid>
        </div>
    );
}

export default Hobbies;
