import React from 'react';
import './AboutMe.css';
import {Box, Card, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import kanza from './kanza.jpg';

function AboutMe() {
    return (
        <div className={"About"}>
            <Typography variant={"h3"}>About me</Typography>

            <Card className={"Me"} sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="h5">
                            Amir Camdzic
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div">
                            Born in April 1999
                            in Eisenstadt, Austria
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div">
                            IT enthusiast
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div">
                            Train nerd
                        </Typography>
                    </CardContent>

                </Box>
                <CardMedia
                    component="img"
                    sx={{ width: 250 }}
                    image={kanza}
                />
            </Card>

            <Grid container spacing={2} className={"Grid"}>
                <Grid item xs={4}>
                    <Card sx={{maxWidth: 345, minHeight: 260}}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Present
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                Technische Universität Wien
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Studying Business Informatics (Bachelor)
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{maxWidth: 345, minHeight: 260}}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                2022 - 2021
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                ETM professional control GmbH, Eisenstadt
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Part-time working student developing new features for WinCC OA.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{maxWidth: 345, minHeight: 260}}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                2021
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                Österreichisches Rotes Kreuz, Mattersburg
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                COVID-19 Antibody/PCR testing and vaccination assistance
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{maxWidth: 345, minHeight: 260}}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                2019
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                Österreichisches Rotes Kreuz, Mattersburg
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Zivildienst (military substitute service)
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{maxWidth: 345, minHeight: 260}}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                2013 - 2018
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                Höhere Technische Bundeslehr- und Versuchsanstalt Wiener Neustadt
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                High school including a Austrian Matura degree
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{maxWidth: 345, minHeight: 260}}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                2009 - 2013
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                Mittelschule Mattersburg
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Middle school
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{maxWidth: 345, minHeight: 260}}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                2005 - 2009
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                Volksschule Marz
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Primary school
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

export default AboutMe;
