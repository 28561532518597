import React from 'react';
import './Home.css';
import {Container, Typography} from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Drawer from "./Drawer";

function Home() {
    return (
        <div className={"Centered"}>
            <div className={"row"}>
                <Typography variant={"h1"}>Hello World!</Typography>
                <Typography variant={"subtitle1"}>Welcome to my website about me: Amir Camdzic</Typography>
            </div>
        </div>
    );
}

export default Home;
