import React from 'react';
import './App.css';
import {Container, createTheme, CssBaseline, ThemeProvider, useMediaQuery} from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Drawer from "./Drawer";
import Home from "./Home";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import AboutMe from "./AboutMe";
import Impressum from "./Impressum";
import Error from "./Error";
import Contacts from "./Contacts";
import Hobbies from "./Hobbies";
import Projects from "./Projects";

function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: prefersDarkMode ? 'dark' : 'light',
                },
            }),
        [prefersDarkMode],
    );

  return (
      <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="App">
              <Drawer/>
              <Container maxWidth="sm" className="Container">
                  <BrowserRouter>
                      <Routes>
                          <Route path="/" element={<Home />} />
                          <Route path="/about" element={<AboutMe />} />
                          <Route path="/projects" element={<Projects />} />
                          <Route path="/hobbies" element={<Hobbies />} />
                          <Route path="/impressum" element={<Impressum />} />
                          <Route path="/contact" element={<Contacts />} />
                          <Route path="*" element={<Error />} />
                      </Routes>
                  </BrowserRouter>
              </Container>
          </div>
      </ThemeProvider>
  );
}

export default App;
