import React from 'react';
import './AboutMe.css';
import {Box, Button, Card, CardActions, CardContent, CardMedia, Container, Grid, Typography} from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import kanza from './kanza.jpg';
import Drawer from "./Drawer";

function Projects() {
    return (
        <Container className={"About"}>
            <Typography variant={"h3"}>Projects</Typography>

            <Grid container spacing={2} className={"Grid"}>
                <Grid item>
                    <Card sx={{width: 360}}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                kanza.dev
                            </Typography>

                            <Typography variant="body2" color="text.secondary">
                                One of my projects in 2023 is actually this website.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" href={"https://kanza.dev"}>kanza.dev</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item>
                    <Card sx={{width: 360}}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                train.kanza.dev
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                On my train travels I try to use routes which I didn't use before. Therefore all my driven train routes are tracked on a OpenStreetMap.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" href={"https://train.kanza.dev"}>Railway Map</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item>
                    <Card sx={{width: 360}}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                GitHub contributions
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Some of my projects can also be found on GitHub
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" href={"https://github.com/devkanza"}>GitHub</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item>
                    <Card sx={{width: 360}}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Educational projects
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                A lot of my projects are done for educational purposes at school or university.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                    <Card sx={{width: 360}}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                PlanPro.at
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                One big project for my graduation at HTBLuVA Wiener Neustadt was PlanPro,
                                a defect detection and documentation system for construction sites consisting of a Web page, an iOS app and an Android app. During this project I was responsible for the Android application.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" href={"https://planpro.at"}>PlanPro</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Projects;
