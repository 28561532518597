import React from "react";
import {Button, Chip} from "@mui/material";

function Drawer() {
    return (
        <div >
            <h1>Oops!</h1>
            <h4>An error occurred</h4>
        </div>
    );
}

export default Drawer;