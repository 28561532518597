import React from "react";
import './Drawer.css';
import {Button, Stack} from "@mui/material";

function Drawer() {
    return (
        <Stack className="Drawer" spacing={1}>
            <Button variant={"text"} href="/">Home</Button>
                <Button variant={"text"} href="/about">About me</Button>
                <Button variant={"text"} href="/projects">Projects</Button>
                <Button variant={"text"} href="/hobbies">Hobbies</Button>
                <Button variant={"text"} href="/contact">Contact</Button>
                <Button variant={"text"} href="/impressum">Impressum</Button>
        </Stack>
    );
}

export default Drawer;